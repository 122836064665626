.wrapper{
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 11;
    @keyframes blur_block_show {
        0%{
            transform: translateX(-100vw);
        }
        100%{
            transform: translateX(0);
        }
    }
    @keyframes blur_block_close {
        0%{
            transform: translateX(0);
        }
        100%{
            transform: translateX(-100vw);
        }
    }
    .blur_block{
        position: relative;
        width: 100vw;
        height: 100vh;
        background: rgba(0,0,0,.5);
        backdrop-filter: blur(5px);
        animation: blur_block_show .5s ease;
        &.hide{
            animation: blur_block_close .5s ease;
        }
    }
    @keyframes cos_white_block_show {
        0%{
            transform: rotate(-45deg) translateY(200vw);
        }
        100%{
            transform: rotate(-45deg) translateY(0);
        }
    }
    @keyframes cos_white_block_close {
        0%{
            transform: rotate(-45deg) translateY(0);
        }
        100%{
            transform: rotate(-45deg) translateY(200vw);
        }
    }
    .cos_white_block{
        position: absolute;
        right: -63vw;
        top: -53vw;
        width: 100vw;
        height: 215vw;
        background: #fff;
        z-index: 12;
        transform: rotate(-45deg);
        animation: cos_white_block_show .5s ease;
        &.hide{
            animation: cos_white_block_close .5s ease;
        }
    }
    @keyframes mobile_menu_links_hide {
        0%{
            transform: translateX(0);
        }
        100%{
            transform: translateX(100vw);
        }
    }
    .mobile_menu_links{
        position: absolute;
        right: 5vw;
        top: 15vw;
        z-index: 99;
        display: flex;
        flex-direction: column;
        &.hide{
            animation: mobile_menu_links_hide .5s ease;
        }
        span{
            color: #333;
            font-size: 6vw;
            width: auto;
            text-align: right;
            margin-bottom: 3vw;
            padding-bottom: 2vw;
        }
    }
    @keyframes close_menu_show {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
    @keyframes close_menu_hide {
        0%{
            opacity: 1;
        }
        100%{
            opacity: 0;
        }
    }
    .close{
        position: absolute;
        left: 10vw;
        top:9.5vw;
        z-index: 99;
        animation: close_menu_show .5s ease;
        &.hide{
            animation: close_menu_hide .5s ease; 
        }
        svg{
            width: 10vw;
            height: 10vw;
            height: auto;
            path{
                fill: var(--accent-color);
            }
        }
    }
}
@media screen and(min-width:768px){
    .wrapper{
        display: none;
    }
}