.wrapper {
    width: 100vw;
    position: relative;
    display: flex;
    overflow-y: hidden;
    section{
        position: relative;
        padding: 0 2vw;
    }
    user-select: none;
    &.noScroll{
        overflow-y: hidden !important;
    }
}
.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width: 10vw;
    height: 100vh;
    padding: 10px;
    z-index: 1;
    background: var(--bg-color);
    border-right: 1px solid rgba(200, 200, 200, 0.3);
    .logo {
        margin-top: 20px;
        img {
            width: 7vw;
            height: auto;
            margin: 0 auto;
        }
    }
    .nav {
        display: flex;
        flex-direction: column;
    }
    .nav__item {
        color: rgba(200, 200, 200, 1);
        font-size: 12px;
        font-family: mb;
        margin-bottom: 5px;

        span {
            color: var(--accent-color);
        }
    }

    .arrow_nav {
        width: 3vw;
        height: 3vw;
        cursor: pointer;

        svg {
            path {
                fill: #fff;
                transition: all .2s ease;
            }
        }

        &:hover {
            svg {
                path {
                    fill: var(--accent-color);
                }
            }
        }
    }
}
.content {
    padding-left: 10vw;
    position: relative;
    width: 100%;
    flex: 1 1;
    overflow-y: auto;
    overflow-x: hidden;
    
}
.mobile_sidebar_btn{
    display: none;
}
.section__titles{
        position: relative;
        width: 60%;
        @keyframes section_subtitle_show {
            0%{
                transform: translatex(-50vw);
                opacity: 0;
            }
            100%{
                transform: translatex(0px);
                opacity: 1;
            }
        }
        @keyframes section_title_show {
            0%{
                transform: translatex(30vw);
                opacity: 0;
            }
            100%{
                transform: translatex(0px);
                opacity: 1;
            }
        }
        .subtitle {
            font-family: "Rocketfont";
            position: absolute;
            width: 100%;
            top: -4vw;
            font-size: 8vw;
            font-size: 8vw;
            color: transparent;
            text-transform: uppercase;
            -webkit-text-stroke: 1px rgba(200, 200, 200, 0.3);
            z-index: 0;
            animation: section_subtitle_show 1s ease;
        }
        .title {
            position: relative;
            margin: 0 auto;
            text-align: left;
            font-size: 2vw;
            line-height: 130%;
            z-index: 1;
            animation: section_title_show 1s ease;
            span {
                font-size: 2.5vw;
                color: var(--accent-color);
                font-family: mb;
                
            }
        }
}
//OFFER SECTION
section.offer {
    width: 100%;
    min-height: 50vw;
    position: relative;
    padding: 0;
    .mobile_logo{
        display: none;
    }
.offer__title {
    position: absolute;
    left: -2.5vw;
    z-index: 2;
    top: 9vw;
    font-family: "RocketFont";
    overflow-y: hidden;
    @keyframes offer_title_main_p_show {
        0%{
            transform: translateY(200px);
            opacity: 0;
        }
        100%{
            transform: translateY(0px);
            opacity: 1;
        }
    }
    @keyframes offer_title_main_span_show {
        0%{
            transform: translateX(120vw);
            opacity: 0;
        }
        100%{
            transform: translateX(0px);
            opacity: 1;
        }
    }
    .main {
        font-size: 5.5vw;
        display: flex;
        align-items: center;
        span {
            margin-left: 2.5vw;
            animation: offer_title_main_span_show 1s forwards;
           
        }
        p{
            animation: offer_title_main_p_show 1s ease;
        }
    }
    @keyframes offer_subtitle_show {
        0%{
            transform: translateY(200px);
            opacity: 0;
        }
        100%{
            transform: translateY(0px);
            opacity: 1;
        }
    }
    .subtitle {
        font-size: 2.2vw;
        animation: offer_subtitle_show 1s ease-out;
    }
}

.offer__cos {
    position: absolute;
    overflow: hidden;
    right: -35.5vw;
    top: -10.5vw;
    width: 100vw;
    height: 60vw;
    background: var(--accent-color);
    transform: rotate(38deg);
    transition: all .5s ease;
    animation: offer_cos_show .9s ease;
}
@keyframes offer_cos_show {
    0%{
        transform: rotate(38deg) translateY(-100vw);
    }
    100%{
        transform: rotate(38deg) translateY(0);
    }
}
@keyframes offer_img_show {
    0%{
        transform: translateY(20vw);
    }
    100%{
        transform: translateY(0);
    }
}
@keyframes offer_pod_text_show {
    0%{
       opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
@keyframes offer_cos_text_show {
    0%{
        opacity: 0;
        transform: rotate(38deg) translateX(100vw);
    }
    100%{
        opacity: 1;
        transform: rotate(38deg) translateX(0);
    }
}
.offer__img {
    height: 27vw;
    position: absolute;
    z-index: 100;
    left: 45%;
    bottom: -.5vw;
    z-index: 2;
    animation: offer_img_show 1s ease-out;
}

.offer__podTexts {
    z-index: 1;
    position: absolute;
    z-index: 1;
    left: 27vw;
    bottom: 13.1vw;
    transform: rotate(-90deg);
    display: flex;
    flex-direction: column;
    animation: offer_pod_text_show 1s ease-in;
    span {
        font-size: 7vw;
        -webkit-text-stroke: 0.1vw rgb(31, 31, 31);
        font-family: mb;
        color: transparent;

        &.white {
            -webkit-text-stroke: 0.1vw rgb(200, 200, 200);
        }
    }
}

.offer__title_cos {
    position: absolute;
    left: 15%;
    top: 45vw;
    width: 100%;
    font-size: 5vw;
    font-family: mb;
    text-transform: uppercase;
    -webkit-text-stroke: 1px rgba(200, 200, 200, 0.3);
    color: transparent;
    transform: rotate(38deg);
    animation: offer_cos_text_show 1s ease;
}

.offer__text {
    position: absolute;
    left: 2vw;
    top: 35vw;
    font-size: 2vw;
    width: 40%;
    line-height: 110%;
}
}
//ABOUT SECTION
section.about {    
    padding-top: 30vh;
    .about__title {
        margin-left: auto;
        .subtitle {
            width: 200%;
            left: -21vw;
        }
    }
    .about__list {
        display: flex;
        margin-top: 5vw;
    }   
    .about__item {
        display: flex;
        margin: 0 2vw;
    
        &.first {
            margin-left: auto;  
            .about__img {
                margin-top: 5vw;
                position: relative;
                width: 16.5vw;
                height: 30vw;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: 100%;
                    height: 100%;
                    &.front {
                        margin: 0 auto;
                        z-index: 1;
                    }
                    &.back {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 5%;
                        left: -5%;
                        opacity: .5;
                    }
                }
            }
            .about__text {
                padding-left: 1.5vw;
                position: relative;
                width: 15vw;
                font-size: 16px;
                margin-left: 4vw;
                line-height: 130%;
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 2px;
                    height: 100%;
                    background: rgba(200, 200, 200, 0.3);
                }
                .subtext {
                    position: absolute;
                    left: -40%;
                    top: 30%;
                    transform: rotate(-90deg);
                    font-family: mb;
                    color: rgba(200, 200, 200, 0.3);
                }
            }
        }
    
        &.second {
            flex-direction: column;
    
            .about__img {
                margin-top: 5vw;
                position: relative;
                width: 30vw;
                height: 20vw;
                display: flex;
                align-items: center;
                justify-content: center;
    
                img {
                    width: 100%;
                    height: 100%;
    
                    &.front {
                        z-index: 1;
                    }
    
                    &.back {
                        position: absolute;
                        left: 5%;
                        top: 5%;
                        opacity: .5;
                    }
                }
            }
    
            .about__text {
                width: 30vw;
                margin-top: 3vw;
                font-size: 16px;
                line-height: 130%;
            }
        }
    }
}
//SERVICES SECTION
section.services {
    padding-top: 15vh;
    @keyframes services_intro_show {
        0%{
            transform: rotate(90deg) translateY(-50vw);
            opacity: 0;
        }
        100%{
            transform: rotate(90deg) translateY(0px);
            opacity: 1;
        }
    }
    .services__intro {
        position: absolute;
        font-family: "Rocketfont";
        right: -25.5vw;
        top: 53vw;
        transform: rotate(90deg);
        font-size: 16vw;
        text-transform: uppercase;
        color: transparent;
        -webkit-text-stroke: 1px var(--accent-color);
        animation: services_intro_show 1s ease;
    }
    .services__titles {
        margin-left: auto;
        width: 59%;
        .subtitle {
            width: 200%;
            left: -22vw;
        }
    }
    .services__list {
        width: 70%;
        .service {
            cursor: default;
            margin: 5vw 0;
            .service__title {
                text-align: left;
                font-size: 3vw;
                font-family: mr;
                padding-bottom: 10px;
                border-bottom: 2px solid var(--accent-color);
                margin-bottom: 10px;
            }
            .service__body {
                display: flex;
                align-items: stretch;
                line-height: 140%;

                .service__assets {
                    width: 30%;
                    display: flex;
                    flex-direction: column;

                    .service__subtitle {
                        color: var(--accent-color);
                        font-size: 1.5vw;
                    }

                    span {
                        display: block;
                        margin-top: auto;
                        font-family: mb;
                        font-size: 2vw;
                        text-transform: uppercase;
                        line-height: 120%;
                        margin-top: 1vw;
                        cursor: pointer;
                        transition: all .2s ease;

                        &:hover {
                            color: var(--accent-color);
                        }
                    }
                }

                .service__info {
                    width: 70%;
                    font-size: 16px;
                    line-height: 140%;
                    
                }
            }

            &.service_right {
                .service__title {
                    text-align: right;
                }

                .service__body {
                    flex-direction: row-reverse;
                }
                .service__assets{
                    align-items: flex-end;

                }
            }
        }
    }
}
//SERVICES WORKS
section.works {
    padding-top: 10vh;
    padding-bottom: 10vh;
    .works__titles {
        position: relative;
        width: 70%;
        .subtitle {
            width: 200%;
            left: 0;
            top: -4vw;
        }
    }

    .works__list {
        position: relative;
        margin-top: 3vw;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .work {
            display: flex;
            flex-direction: column;
            width: 40%;

            .work__img {
                width: 99%;
                height: 22.5vw;
                border: 1px solid rgba(200, 200, 200, 0.3);
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                transition: .2s;

                .img_back {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 1;
                    opacity: .1;
                }

                .img_front {
                    width: 90%;
                    height: 83%;
                    box-shadow: 0 0 10px rgba(200, 200, 200, .2);
                    border-radius: 0.2vw;
                    z-index: 4;
                    position: absolute;
                    transition: .2s;
                }
            }

            .work__info {
                .work__name {
                    font-size: 20px;
                    margin: .5vw 0;
                }

                .work__type {
                    margin-bottom: .5vw;
                    color: rgba(200, 200, 200, 1);
                }

                .work__description {
                    line-height: 130%;
                }
            }

            &:hover {
                .work__img {
                    border: 1px solid var(--accent-color);

                    .img_front {
                        transform: scale(1.01);
                    }
                }
            }
        }
    }

    .works__btn {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: 40px;
        font-size: 16px;
        color: #fff;
        background: #030b1f;
        text-decoration: none;
        border: 2px solid #b240ff;
        margin: 0 auto;
        margin-top: 3vw;
        transition: all .2s ease;
        overflow: visible;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            border: 2px solid #fff;
            z-index: -1;
            transition: all .2s ease;
        }

        &:hover {
            &::after {
                opacity: 1;
                transform: translateX(5px) translateY(5px);
            }
        }

    }
}
//SERVICES STAGES
section.stages {
    padding-top: 10vh;
    padding-bottom: 10vh;
    .stages__titles {
        margin-left: auto;
        .subtitle {
            left: -.2vw;
        }
    }
    .stages__content {
        margin-top: 2vw;
        display: flex;
        align-items: flex-start;
    }
    .stages__list {
        display: flex;
        flex-direction: column;
        width: 60%;
        .stage {
            display: flex;
            align-items: center;
            border-bottom: 2px solid #fff;
            padding: 3vw 0;
            .stage__title {
                width: 35%;
                font-size: 2vw;
                line-height: 120%;
            }
            .stage__text {
                width: 50%;
                font-size: 16px;
                line-height: 120%;
            }

            .stage__number {
                width: 15%;
                font-family: mb;
                font-size: 3vw;
                color: var(--accent-color);
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }

    .stages__info {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 30%;
        margin-left: auto;
        padding-top: 3vw;

        .stages__intro {
            font-family: "Rocketfont";
            position: absolute;
            transform: rotate(90deg);
            left: -87%;
            top: 48%;
            font-size: 3.5vw;
            -webkit-text-stroke: 1px rgba(200, 200, 200, 0.5);
            color: transparent;
            text-transform: uppercase;
        }

        .title {
            font-size: 1.5vw;
            color: #fff;
            font-family: mb;
            border-bottom: 1px solid var(--accent-color);
            padding-bottom: 5px;
        }

        .text {
            margin: 2vw 0;
            font-size: 16px;
            color: rgb(150, 150, 150);
            line-height: 120%;

            span {
                color: #fff;
            }
        }

        .stages__img {
            width: 100%;
            height: 20vw;
           
        }
        .stages__img {
            position: relative;
            width: 95%;
            height: 20vw;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 1vw;
            .front {
                width: 100%;
            height: 100%;
                    z-index: 1;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                }
            .back {
            width: 100%;
            height: 100%;
                    position: absolute;
                    left: 5%;
                    top: 5%;
                    opacity: .5;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                }
        }
    }
}
//SERVICES CONTACT
section.contact {
    padding-top: 10vh;
    padding-bottom: 5vh;
    @keyframes contact_intro_show {
        0%{
            transform: rotate(90deg) translateY(10vw);
            opacity: 0;
        }
        100%{
            transform: rotate(90deg) translateY(0px);
            opacity: 1;
        }
    }
    .contact__intro{
        position: absolute;
        left: -10vw;
        font-size: 2.5vw;
        top: 50%;
        transform: rotate(90deg);
        color: rgba(200,200,200,1);
        animation: contact_intro_show 1s ease;
    }
    .contact__titles {
        .subtitle {
            left: -.2vw;
        }
    }
    .contact__content{
        display: flex;
        align-items: flex-start;
    }
    .contact__info{
        position: relative;
        margin-left: auto;
        display: flex;
        flex-direction: column;
        width: 40%;
        .contact__img {
            margin-left: auto;
            margin-top: -6vw;
            position: relative;
            width: 20vw;
            height: 30vw;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 1vw;
            img {
                width: 100%;
                height: 100%;
                &.front {
                    z-index: 1;
                }
                &.back {
                    position: absolute;
                    left: 5%;
                    top: 5%;
                    opacity: .5;
                }
            }
        }
        .contact__links{
            margin-left: auto;
            display: flex;
            flex-direction: column;
            margin-top: 2vw;
            a{
                display: flex;
                align-items: center;
                color: #fff;
                font-size: 2vw;
                margin: 1vw 0;
                transition: all .2s ease;
                svg{
                    width: 30px;
                    height: auto;
                    margin-right: 10px;
                }
                &:hover{
                    color: var(--accent-color);
                }
            }
        }
    }
}
@media screen and(max-width:768px) {
    .wrapper{
        section{
            padding: 0 5vw;
        }
    }
    .sidebar{
        display: none;
    }
    .mobile_sidebar_btn{
        position: fixed;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        right: 2vw;
        top: 5vw;
        width: 10vw;
        height: 10vw;
        background: var(--accent-color);
        z-index: 5;
        span{
            display: block;
            width: 7vw;
            height: 0.8vw;
            background: #fff;
            margin-bottom: 1vw;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .content{
        padding: 0;
    }
    .section__titles{
        width: 100% !important;
        margin-left: 0 !important;
        .subtitle{
            position: relative;
            left: 0 !important;
            top: 0 !important;
            font-size: 50px;
            -webkit-text-stroke: 1px rgba(200, 200, 200, 0.5);
            text-align: left;
            span{
                display: none;
            }
        }
        .title{
            font-size: 18px;
            width: 100%;
            span{
                font-size: 20px;
            }
        }
    }
    section.offer{
        min-height: 100vw;
        .mobile_logo{
            position: absolute;
            top: 5vw;
            left: 5vw;
            display: block;
            img{
                width: 30vw;
                height: auto;
            }
        }
        .offer__title{
            left: 5vw;
            top: 42vw;
            margin-bottom: 1vw;
            .main{
                font-size: 10vw;
            }
            .subtitle{
                font-size: 3.8vw;
            }
        }
        .offer__cos{
            width: 120%;
            height: 124vw;
            top: -25.5vw;
            right: -85%;
            transform: rotate(38deg);
        }
        .offer__podTexts{
            display: none;
        }
        .offer__img{
            left: 17%;
            height: 45vw;
        }
        .offer__title_cos{
            display: none;
        }
        .offer__text{
            top: 70vw;
            font-size: 16px;
            width: 80%;
            left: 5vw;
            font-family: mb;
            line-height: 120%;
        }
    }
    section.about{
        .about__list{
            flex-direction: column-reverse;
            .about__item{
                width: 100%;
                margin: 0;
                flex-direction: column;
                &:first-child{
                    .about__img{
                        display: none;
                    }
                    .about__text{
                        margin-top: 5vw;
                        border-left: none;
                        &::before{
                            display: none;
                        }
                    }
                }
                &:last-child{
                    .about__text{
                        display: none;
                    }
                }
                .about__img{
                    width: 90%;
                    height: auto;
                    margin-top: 0;
                    img{
                        width: 100%;
                    }
                    .back{
                        background: var(--accent-color);
                    }
                }
                .about__text{
                    width: 100%;
                    margin-top: 50px;
                    margin-left: 0;
                }
            }
        }
    }
    section.services{
        padding-top: 5vh;
        .services__intro{
            display: none;
        }
        .services__list{
            width: 100%;
            .service{
                .service__title{
                    font-size: 24px;
                    font-family: mb;
                }
                .service__body{
                    flex-direction: column-reverse;
                    .service__info{
                        width: 100%;
                    }
                    .service__assets{
                        width: 100%;
                        .service__subtitle{
                            display: none;
                        }
                        span{
                            margin-top: 10px;
                            font-size: 18px;
                            border: 2px solid var(--accent-color);
                            padding: 10px;
                            text-align: center;
                            width: 50%;
                        }
                    }
                }
                &.service_right{
                    .service__body{
                        flex-direction: column-reverse;
                        .service__assets{
                            span{
                                margin-left: auto;
                            }
                        }
                    }
                }
            }
        }
    }
    section.works{
        padding-top: 5vh;
        .works__list{
            flex-direction: column;
            .work{
                width: 100%;
                margin-bottom: 20px;
                .work__img{
                    height: 60vw;
                }
                .work__info{
                    .work__name{
                        margin: 10px 0;
                    }
                    .work__type{
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    section.stages{
        padding-top: 5vh;
        .stages__content{
            flex-direction: column;
        }
        .stages__list{
            width: 100%;
            .stage{
                .stage__title{
                    font-size: 20px;
                    width: 80%;
                }
                .stage__text{
                    display: none;
                }
                .stage__number{
                    width: 20%;
                    font-size: 30px;
                }
            }
        }
        .stages__info{
            margin-top: 10px;
            width: 100%;
            .title{
                font-size: 22px;
            }
            .text{
                margin: 10px 0;
            }
            .stages__img{
                height: 60vw;
            }
        }
    }
    section.contact{
        padding: 5vh 5vw;
        .contact__intro{
            top: 95vw;
            left: -30vw;
            font-size: 7vw;
        }
        .contact__content{
            flex-direction: column-reverse;

            .contact__info{
                width: 100%;
                .contact__img{
                    display: none;
                }
                .contact__links{
                    width: 100%;
                    margin-top: 0;
                    a{
                        width: 100%;
                        text-align: right;
                        font-size: 20px;
                        justify-content: flex-end;
                    }
                }
            }
        }
    }
}
@media screen and(min-width:769px) and (max-width:1200px) {
    section.about{
        .about__list{
            .about__item{
                width: 50%;
                &:first-child{
                    margin-left: 0;
                    .about__img{
                        width: 20vw;
                        height: 40vw;
                    }
                    .about__text{
                        width: 30vw;
                    }
                }
            }
        }
    }
}
@media screen and(min-width: 1925px){
    .sidebar{
        .nav{ 
            .nav__item{
                font-size: .7vw;
            }
        }
    }
    section.about{
        .about__list{
            .about__item{
                .about__text{
                    font-size: 1vw;
                }
            }
        }
    }
    section.services{
        .services__list{
            .service{
                .service__body{
                    .service__info{
                        font-size: 1vw;
                    }
                }
            }
        }
    }
    section.works{
        .works__list{
            .work{
                .work__info{
                    .work__name{
                        font-size: 1.5vw;
                    }
                    .work__type{
                        font-size: 1vw;
                    }
                    .work__description{
                        font-size: .7vw;
                    }
                }
            }
        }
    }
    section.stages{
        .stages__list{
            .stage{
                .stage__text{
                    font-size: 1vw;
                }
            }
        }
        .stages__info{
            .text{
                font-size: 1vw;
            }
        }
    }
}
