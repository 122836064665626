.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
}
    .title {
        position: relative;
        font-size: 20vw;
        font-family: "Rocketfont";
        color: var(--bg-color);
        -webkit-text-stroke: 0.1vw var(--accent-color);
        z-index: 9;

        &::after {
            position: absolute;
            content: "404";
            left: 0;
            top: 0;
            transform: translateX(-8px) translateY(10px);
            width: 100%;
            height: 100%;
            z-index: 0;
            -webkit-text-stroke: 0.1vw #fff;
        }
    }

    .text {
        margin-top: 20px;
        font-size: 3vw;
        color: rgba(200, 200, 200, 1);
    }

    .btn_go {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: 40px;
        font-size: 16px;
        color: #fff;
        background: #030b1f;
        text-decoration: none;
        border: 2px solid #b240ff;
        margin: 0 auto;
        margin-top: 3vw;
        transition: all .2s ease;
        overflow: visible;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            border: 2px solid #fff;
            z-index: -1;
            transition: all .2s ease;
        }

        &:hover {
            &::after {
                opacity: 1;
                transform: translateX(5px) translateY(5px);
            }
        }

    }
@media screen and(max-width:768px) {
    .title{
        font-size: 30vw;

    }
}