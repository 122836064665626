@import './reset.scss';
@font-face 
{
    font-family:mb; 
    src: url(m_b.ttf); 
}
@font-face {
	font-family: "RocketFont";
	src: url(Rocketfont.woff2);
  }
@font-face 
{
    font-family:mr; 
    src: url(m_r.ttf); 
}
:root{
    --bg-color: #030b1f;
    --accent-color: #b240ff;
}
body{
    background: #030b1f;
	color: #fff;
    font-family: serif;
    font-family: mr;
    font-size: 14px;
    overflow-x: hidden;
}
.modal_icon.swal2-success [class^=swal2-success-line] {
	background-color: #15ed6b!important;
}

.modal_icon.swal2-success .swal2-success-ring {
	border: .25em solid rgba(0,227,150,.3)!important;
}
.modal_popup {
    border-radius: 0 !important;
    min-width: 300px !important;
	box-shadow: 0px 6px 10px 0px rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12), 0 3px 5px -1px rgba(0,0,0,.2)!important;
}
::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	border-radius: 10px;
	background-color: transparent;
}
::-webkit-scrollbar {
	width: 5px;
	height: 4px;
	background-color: transparent;
}
::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #505562;
}