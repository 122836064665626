.wrapper {
    position: relative;
    margin-top: 1vw;
    width: 60%;
    padding-left: 4vw;
    .inputBlock {
        margin-bottom: 2vw;
        width: 80%;
        input, textarea{
            width: 100%;
            border: none;
            border-bottom: 1px solid rgba(200, 200, 200, 0.7);
            outline: none;
            background-color: transparent;
            font-size: 14px;
            color: #fff;
            height: 2.5vw;
            transition: all .5s ease;
            border-radius: 0;
            &::placeholder{
                color: #4c5056;
            }
            &:active, &:focus{
                border-bottom: 1px solid var(--accent-color);
            }
        }
        textarea{
            padding-top: 0;
            border: 1px solid rgba(200, 200, 200, 0.7);
            height: 10vw;
            font-family: mr;
            resize: none;
            padding-top: 0.2vw;
            padding-left: 0.2vw;
            transition: all .5s ease;
            &:active, &:focus{
                border: 1px solid var(--accent-color);
            }
        }
    }
    .contact__policy{
        width: 80%;
        display: flex;
        align-items: center;
        p{
            width: 80%;
            font-size: 12px;
            line-height: 120%;
        }
        span{
            position: relative;
            margin-left: auto;
            width: 35px;
            height: 35px;
            border: 1px solid rgba(200, 200, 200, 0.7);
            cursor: pointer;
            overflow: hidden;
            &::after{
                content: '';
                width: 26px;
                height: 1px;
                position: absolute;
                left: 3px;
                top: 16px;
                transform: rotate(45deg) translateX(-40px);
                background: var(--accent-color);
                transition: all .2s ease;
            }
            &::before{
                content: '';
                width: 26px;
                height: 1px;
                position: absolute;
                left: 3px;
                top: 16px;
                transform: rotate(-45deg) translateX(40px);
                background: var(--accent-color);
                transition: all .2s ease;
            }
            &.active{
                &::after{
                    transform: rotate(45deg) translateX(0px);
                }
                &::before{
                    transform: rotate(-45deg) translateX(0px);
                }
            }
        }
    }
    .contact__btn {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: 40px;
        font-size: 16px;
        color: #fff;
        background: #030b1f;
        text-decoration: none;
        border: 2px solid #b240ff;
        margin-top: 3vw;
        transition: all .2s ease;
        overflow: visible;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            border: 2px solid #fff;
            z-index: -1;
            transition: all .2s ease;
        }

        &:hover {
            &::after {
                opacity: 1;
                transform: translateX(5px) translateY(5px);
            }
        }

    }
}
@media screen and(max-width:768px) {
    .wrapper{
        width: 100%;
        padding-left: 10vw;

        .inputBlock{
            width: 100%;
            margin-bottom: 10px;
            input{
                height: 40px;
            }
            textarea{
                padding-left: 5px;
                padding-top: 5px;
                height: 100px;
            }
        }
        .contact__policy{
            width: 100%;
            flex-direction: row-reverse;
            span{
                margin-left: 0;
            }
            p{
                margin-left: auto;
            }
        }
        .contact__btn{
            width: 100%;
        }
    }
}

@media screen and(min-width: 1925px){
    .wrapper{
        .inputBlock{
            input, textarea{
                font-size: .8vw;
            }
        }
        .contact__policy{
            p{
                font-size: .7vw;
            }
        }
    }
}