.wrapper{
    position: relative;
}
@keyframes offer_show {
    0%{
        opacity: 0;
        transform: translateY(-20vw);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}
.offer{
    padding: 10vh 10vh;
	padding-bottom: 0;
	background: #b240ff;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 40vh;
	overflow: hidden;
	user-select: none;
    animation: offer_show 1s ease;
    .offer__link{
        position: absolute;
        top: 0;
        left: 0;
        // background: #fff;
        width: 5vw;
        height: 5vw;
        z-index: 2;
        color: #333;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Rocketfont";
        letter-spacing: 0.05em;
        cursor: pointer;
        i{
            font-size: 2vw;
            transition: all .2s ease;
            color: #fff;
        }
        &:hover{
            i{
                transform: translateX(-5px);
            }
        }
    }
    .offer__title{
        font-family: "RocketFont";
	    font-weight: 400;
	    font-size: 8vw;
	    text-align: center;
	    z-index: 1;
	    text-shadow: 0 3px 6px rgba(0,0,0,.2);
    }
    @keyframes offer_back_show {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
    .offer__back{
        animation: offer_back_show 1s ease;
        span{
            position: absolute;
            top:35%;
            font-size: 10vh;
            font-family: "RocketFont";
            color: transparent;
            -webkit-text-stroke: 1px rgba(255,255,255,.2);
            text-transform: uppercase;
            transform: rotate(-45deg);
            z-index: 0;
            animation: offer_back_show 1s ease;
            &.offer__textBack{
                position: absolute;
                left: -10%;
                -webkit-text-stroke: 1px rgba(0,0,0,.2);
            }
            &.offer__textBack1{
                position: absolute;
                left: 0;
            }
            &.offer__textBack2{
                position: absolute;
                left: 10%;
                -webkit-text-stroke: 1px rgba(0,0,0,.2);
            }
            &.offer__textBack3{
                position: absolute;
                left: 20%;
            }
            &.offer__textBack4{
                position: absolute;
                left: 30%;
                -webkit-text-stroke: 1px rgba(0,0,0,.2);
            }
            &.offer__textBack5{
                position: absolute;
                left: 40%;
            }
            &.offer__textBack6{
                position: absolute;
                left: 50%;
                -webkit-text-stroke: 1px rgba(0,0,0,.2);
            }
            &.offer__textBack7{
                position: absolute;
                left: 60%;
            }
            &.offer__textBack8{
                position: absolute;
                left: 70%;
                -webkit-text-stroke: 1px rgba(0,0,0,.2);
            }
            &.offer__textBack9{
                position: absolute;
                left: 80%;
            }
        }
    }
    .offer__subtitle{
        color: #fff;
	    opacity: .8;
	    font-size: 1vw;
        margin-top: 20px;
    }
    @keyframes offer_img_show {
        0%{
            transform: translateY(50vw);
        }
        100%{
            transform: translateY(0);
        }
    }
    .offer__img{
        position: absolute;
        width: 20vw;
        height: auto;
        animation: offer_img_show 1s ease;
    }
    .offer__img1{
        left: 0;
        bottom: 0;
    }
    .offer__img2{
        right: 0;
        bottom: 0;
    }
}
.projects__container{
    width: 90%;
    margin: 0 auto;
}
.projects__list{
    display: flex;
    align-items: flex-start;
    margin: 2.5vw 0;
    flex-wrap: wrap;
    justify-content: space-around;
    .project {
        display: flex;
        flex-direction: column;
        width: 42.5%;
        margin-bottom: 3vw;
        .project__img {
            width: 99%;
            height: 25vw;
            border: 1px solid rgba(200, 200, 200, 0.3);
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            transition: .2s;
            .img_back {
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 1;
                opacity: .1;
            }

            .img_front {
                width: 90%;
                height: 83%;
                box-shadow: 0 0 10px rgba(200, 200, 200, .2);
                border-radius: 0.2vw;
                z-index: 10;
                position: absolute;
                transition: .2s;
            }
        }

        .project__info {
            .project__name {
                font-size: 20px;
                margin: .5vw 0;
                text-align: center;
            }

            .project__type {
                margin-bottom: .5vw;
                color: rgba(200, 200, 200, 1);
                text-align: center;
            }

            .project__description {
                width: 70%;
                margin: 0 auto;
                line-height: 130%;
                text-align: center;
            }
        }

        &:hover {
            .project__img {
                border: 1px solid var(--accent-color);

                .img_front {
                    transform: scale(1.01);
                }
            }
        }
    }
}
@media screen and(max-width:768px) {
    .offer{
        height: 40vw;
        .offer__back{
            span{
                font-size: 12vw;
                &:nth-child(odd){
                    display: none;
                }
                &.offer__textBack1{
                    left: -20%;
                }
                &.offer__textBack3{
                    left: 0;
                }
                &.offer__textBack5{
                    left: 20%;
                }
                &.offer__textBack7{
                    left: 40%;
                }
                &.offer__textBack9{
                    left: 60%;
                }
            }
        }
        .offer__img{
            width: 33vw;
            &.offer__img1{
                left: -2vw;
            }
            &.offer__img2{
                right: -2vw;
            }
        }
        .offer__title{
            font-size: 10vw;
        }
        .offer__subtitle{
            width: 80%;
            font-size: 2vw;
            margin: 2vw 0;
            line-height: 115%;
            text-align: center;
        }
        .offer__link{
            left: 2vw;
            top: 2vw;
            width: 8vw;
            height:8vw;
            i{
                font-size: 7vw;
                color: #fff;
            }
        }
    }
    .projects__list{
        margin-top: 20px;
        .project{
            width: 100%;
            margin-bottom: 30px;
            .project__img{
                height: 55vw;
            }
            .project__info{
                .project__name{
                    margin-top: 10px;
                    text-align: left;
                }
                .project__type{
                    padding: 10px 0;
                    text-align: left;
                }
                .project__description{
                    width: 100%;
                    text-align: left;
                }
            }
        }
    }
}