.wrapper{
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9;
    @keyframes blur_block_show {
        0%{
            transform: translateX(-110vw);
        }
        100%{
            transform: translateX(0);
        }
    }
    @keyframes blur_block_close {
        0%{
            transform: translateX(0);
        }
        100%{
            transform: translateX(-110vw);
        }
    }
    .blur_block{
        position: relative;
        width: 100vw;
        height: 100vh;
        background: rgba(0,0,0,.5);
        backdrop-filter: blur(5px);
        animation: blur_block_show 1s ease;
        &.hide{
            animation: blur_block_close 1s ease;
        }
    }
    @keyframes cos_white_block_show {
        0%{
            transform: rotate(52deg) translateX(200vw);
        }
        100%{
            transform: rotate(52deg) translateX(0);
        }
    }
    @keyframes cos_white_block_close {
        0%{
            transform: rotate(52deg) translateX(0);
        }
        100%{
            transform: rotate(52deg) translateX(200vw);
        }
    }
    .cos_white_block{
        position: absolute;
        right: -62vw;
        top: -26vw;
        width: 150vw;
        height: 80vw;
        background: #fff;
        z-index: 10;
        transform: rotate(52deg);
        animation: cos_white_block_show 1s ease;
        &.hide{
            animation: cos_white_block_close 1s ease;
        }
    }
    @keyframes mobile_menu_links_hide {
        0%{
            transform: translateX(0);
        }
        100%{
            transform: translateX(100vw);
        }
    }
    .mobile_menu_links{
        position: absolute;
        right: 5vw;
        top: 20vw;
        z-index: 11;
        display: flex;
        flex-direction: column;
        &.hide{
            animation: mobile_menu_links_hide 1s ease;
        }
        a{
            color: #333;
            font-size: 6vw;
            width: auto;
            text-align: right;
            margin-bottom: 3vw;
            padding-bottom: 2vw;
            border-bottom: 1px solid var(--accent-color);
        }
    }
    @keyframes close_menu_show {
        0%{
            opacity: 0;
            transform: translateX(150vw);
        }
        100%{
            opacity: 1;
            transform: translateX(0);
        }
    }
    @keyframes close_menu_hide {
        0%{
            opacity: 1;
            transform: translateX(0);
        }
        100%{
            opacity: 0;
            transform: translateX(150vw);
        }
    }
    .close{
        position: absolute;
        right: 2vw;
        top:1vw;
        z-index: 12;
        animation: close_menu_show 1s ease;
        cursor: pointer;
        &.hide{
            animation: close_menu_hide 1s ease; 
        }
        svg{
            width: 3vw;
            height: 3vw;
            height: auto;
            path{
                fill: var(--accent-color);
            }
        }
    }
    .form {
        right: 2vw;
        top: 5vw;
        position: absolute;
        margin-top: 1vw;
        width: 30vw;
        padding-left: 4vw;
        z-index: 12;
        animation: close_menu_show 1s ease;
        &.hide{
            animation: close_menu_hide 1s ease;
        }
        .form__title{
            color: #000;
            text-align: right;
            font-size: 26px;
        }
        .form__subtitle{
            text-align: right;
            color: var(--accent-color);
            margin: 10px 0;
        }
        .inputBlock {
            margin-bottom: 2vw;
            width: 100%;
            input, textarea{
                width: 100%;
                border: none;
                border-bottom: 1px solid rgba(200, 200, 200, 0.7);
                outline: none;
                background-color: transparent;
                font-size: 14px;
                color: #000;
                height: 2.5vw;
                transition: all .5s ease;
                &::placeholder{
                    color: #4c5056;
                }
                &:active, &:focus{
                    border-bottom: 1px solid var(--accent-color);
                }
            }
            textarea{
                padding-top: 0;
                border: 1px solid rgba(200, 200, 200, 0.7);
                height: 10vw;
                font-family: mr;
                resize: none;
                padding-top: 0.2vw;
                padding-left: 0.2vw;
                transition: all .5s ease;
                &:active, &:focus{
                    border: 1px solid var(--accent-color);
                }
            }
        }
        .form__policy{
            width: 100%;
            display: flex;
            align-items: center;
            p{
                width: 80%;
                font-size: 12px;
                line-height: 120%;
                color: #333;
            }
            span{
                position: relative;
                margin-left: auto;
                width: 35px;
                height: 35px;
                border: 1px solid rgba(200, 200, 200, 0.7);
                cursor: pointer;
                overflow: hidden;
                &::after{
                    content: '';
                    width: 26px;
                    height: 1px;
                    position: absolute;
                    left: 3px;
                    top: 16px;
                    transform: rotate(45deg) translateX(-40px);
                    background: var(--accent-color);
                    transition: all .2s ease;
                }
                &::before{
                    content: '';
                    width: 26px;
                    height: 1px;
                    position: absolute;
                    left: 3px;
                    top: 16px;
                    transform: rotate(-45deg) translateX(40px);
                    background: var(--accent-color);
                    transition: all .2s ease;
                }
                &.active{
                    &::after{
                        transform: rotate(45deg) translateX(0px);
                    }
                    &::before{
                        transform: rotate(-45deg) translateX(0px);
                    }
                }
            }
        }
        .form__btn {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 40px;
            font-size: 16px;
            color: #fff;
            text-decoration: none;
            border: 2px solid #b240ff;
            color: #333;
            margin-top: 2vw;
            transition: all .2s ease;
            overflow: visible;
            cursor: pointer;
    
            &:hover {
                background: var(--accent-color);
                color: #fff;
            }
    
        }
    }
}
@media screen and(max-width:768px){
    .wrapper{
        .close{
            top: 3vw;
            svg{
                width: 10vw;
                height: 10vw;
            }
        }
        @keyframes cos_white_block_show {
            0%{
                transform: translateX(200vw);
            }
            100%{
                transform: translateX(0);
            }
        }
        @keyframes cos_white_block_close {
            0%{
                transform: translateX(0);
            }
            100%{
                transform: translateX(200vw);
            }
        }
        .cos_white_block{
            width: 100vw;
            height: 100vh;
            top: 0;
            right: 0;
            transform: rotate(0);
            animation: cos_white_block_show 1s ease;
            &.hide{
                animation: cos_white_block_close 1s ease; 
            }
        }
        .form{
            width: 100vw;
            right: 0;
            top: 10vh;
            padding: 0 5vw;
            .inputBlock{
                input{
                    height: 40px ;
                }
                textarea{
                    height: 30vh;
                }
                margin-bottom: 10px;
            }
        }
    }
}
@media screen and(min-width: 1925px){
    .wrapper{
        .form{
            .inputBlock{
                input, textarea{
                    font-size: .8vw;
                }
                margin-bottom: 1vw;
            }
            .form__btn{
                margin-top: 1vw;
            }
        }
    }
}