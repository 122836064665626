.wrapper{
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background: var(--bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h2{
        font-size: 15vw;
        font-family: mb;
        color: transparent;
        -webkit-text-stroke: 1px rgba(200, 200, 200, 0.5);
    }
    p{
        margin-top: 10px;
        font-size: 16px;
        letter-spacing: .05em;
    }
    .line{
        position: relative;
        width: 80vw;
        margin: 0 auto;
        margin-top: 50px;
        @keyframes lineLoading {
            0%{
                width: 0%;
            }
            100%{
                width: 100%;
            }
        }
        span{
            width: auto;
            display: block;
            height: 2px;
            background: var(--accent-color);
            animation: lineLoading 2s ease;
        }
        @keyframes hideLoading {
            0%{
                opacity: 1;
            }
            100%{
                opacity: 0;
            }
        }
    }
    &.hide{
        animation: hideLoading 1s ease;
    }
}